import React from "react";
import cn from "classnames";
import * as styles from "./BackgroundFadeContainer.module.scss";
import PageMargins from "@components/PageMargins/PageMargins";

const ANIMATION = {
	"data-sal": "slide-up",
	"data-sal-duration": "700",
	"data-sal-easing": "ease-in-out",
};

type BackgroundFadeContainerProps = {
	type:
		| "blueFade"
		| "dpaBlue"
		| "purpleBlueFade"
		| "solidBlue"
		| "pinkFade"
		| "solidLightPink"
		| "darkBlue"
		| "featureCardGray"
		| "white";
	className?: string;
	bodyClass?: string;
	skewTop?: boolean;
	skewBottom?: boolean;
	withHeaderPadding?: boolean;
	withCustomHeaderPadding?: boolean;
	noAnimation?: boolean;
	/** Percentage of the height the background should cover */
	fadeHeight?: number;
	backgroundClass?: string;
	/** use PageMargins component to set left / right margin [default = true]  */
	autoMargin?: boolean;
	children: React.ReactNode;
	widthOnLargeScreensClass?:string;
};

export default function BackgroundFadeContainer({
	type,
	skewTop = false,
	skewBottom = false,
	withHeaderPadding = false,
	withCustomHeaderPadding = false,
	noAnimation = false,
	className = "",
	bodyClass,
	fadeHeight = 100,
	backgroundClass,
	autoMargin = true,
	children,
	widthOnLargeScreensClass
}: BackgroundFadeContainerProps) {
	return (
		<div
			className={cn(
				styles.fade,
				styles.flexColumnCenter,
				{
					[styles.skewTop]: skewTop,
					[styles.skewBottom]: skewBottom,
				},
				className
			)}
		>
			<div
				className={cn(
					styles.backgroundImage,
					styles[type],
					backgroundClass,
					{
						[styles.skewTop]: skewTop,
						[styles.skewBottom]: skewBottom,
						[styles.withHeaderPadding]: withHeaderPadding,
						[styles.withCustomHeaderPadding]:
							withCustomHeaderPadding,
					}
				)}
				style={{ height: `${fadeHeight}%` }}
			></div>
			<div
				{...(withHeaderPadding || noAnimation ? {} : ANIMATION)}
				style={{ position: "absolute", top: 0 }}
			></div>
			<div
				className={cn(styles.fullWidth, bodyClass, {
					[styles.withHeaderPadding]: withHeaderPadding,
					[styles.withCustomHeaderPadding]: withCustomHeaderPadding,
				})}
			>
				{autoMargin ? <PageMargins widthOnLargeScreensClass={widthOnLargeScreensClass}>{children}</PageMargins> : children}
			</div>
		</div>
	);
}
